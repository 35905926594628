import { FC } from 'react';
import { Faq } from '../../../models/faq';

export type Props = {
  value?: string | undefined
  initialLists: Faq[] | undefined
  goToDetail: (id: number) => void
  searchFaqLists: Faq[]
};

const RelatedFaq: FC<Props> = ({
  value,
  initialLists,
  goToDetail,
  searchFaqLists
}) => (
  <>
    {initialLists && value === '' ?
      <>
        {initialLists?.map(initialList =>
          <div key={initialList.Id} className="faq" onClick={() => goToDetail(initialList.Id)}>{initialList.Question}</div>
        )}
      </>
      :
      <>
        {searchFaqLists?.map(searchFaqList =>
          <div key={searchFaqList.Id} className="faq" onClick={() => goToDetail(searchFaqList.Id)}>{searchFaqList.Question}</div>
        )}
      </>
    }</>
);

export default RelatedFaq;
