export interface User {
  profile?: Profile;
  topics?: Topic[];
  rooms?: Room[];
}

export type ApiResponseUser = [
  ApiResponseProfile,
  ApiResponseTopic[],
  ApiResponseRoom[]
]

const splitCommaStringIntoArray = (s: string) => s.split(",").map((s: string) => parseInt(s));

export const convertFileldsFromApiResponseUser = (user: ApiResponseUser): User => {
  const profile = user[0];
  const topics = user[1];
  const rooms = user[2];

  const imageOrder = splitCommaStringIntoArray(profile.image_order);
  const iconSrcIdx = imageOrder[0];
  const iconSrc = `https://images.yourblossoms.com/faceimages/users/${profile.eid}/${iconSrcIdx}/middle.jpg`

  return {
    profile: {
      // BasicProfile
      name: profile.name,
      intro: profile.intro,
      iconSrc,
      // followLink: string;
      numFollow: profile.num_likes_user,
      numFollower: profile.num_liked,
      numPost: profile.num_post,
      numChat: profile.num_chat,

      // DetailedProfile
      bestlove: profile.bestlove,
      firstlove: profile.firstlove,
      habitat: profile.habitat,
      jirai: profile.jirai,
      food: profile.food,
      drink: profile.drink,
      agerange: profile.agerange,
      placeLive: profile.place_live,

      // Others
      imageOrder: profile.image_order.split(",").map((s: string) => parseInt(s)),
    },
    rooms: rooms.map((room: ApiResponseRoom) => ({
      ...room,
      accessControl: room.access_control === 0 ? AccessControlType.PUBLISH : AccessControlType.LIMITED, // 0:公開ルーム、1: 両思い限定ルーム
      creatorName: room.creator_name,
      creatorEid: room.creator_eid,
      numChats: room.num_chats,
      numContents: room.num_contents,
      numLiked: room.num_liked,
      numParticipants: room.num_participants,
      numTotalLiked: room.num_total_liked,
      roomIconType: room.type === 2 ? RoomIconType.IMAGE : RoomIconType.CHAT, // 1:チャットルーム、2:画像ルーム
      roomImageOrder: splitCommaStringIntoArray(room.room_image_order),
      roomImageIcon: `https://images.yourblossoms.com/roomiconimages/${room.id}/${splitCommaStringIntoArray(room.room_image_order)[0]}/image.jpg`,
      totalTimeSpent: room.total_time_spent,
      createdAt: room.created_at,
      updatedAt: room.updated_at,
      isDisplay: room.status === 0, // 0以外は削除されたもの
    }))
      .filter((room: Room) => (room.isDisplay)),
    topics: topics.map((topic: ApiResponseTopic) => ({
      ...topic,
      numUsers: topic.num_users,
      orderId: topic.order_id,
      picId: topic.pic_id
    }))
  }
}

export interface ProfileCommon {
  name: string;
  intro: string;

  bestlove: string;
  firstlove: string;
  habitat: string;
  jirai: string;
  food: string;
  drink: string;
  agerange: string;
  placeLive: string;

  age?: number;
  eid?: string;
  fukidashi?: string;
  job?: string;
  ofuse?: string;
  sex?: number;
  state?: string;
  tendency?: string;
  title?: string;
}

export interface ApiResponseProfile extends ProfileCommon {
  image_order: string;
  num_blocked: number;
  num_booked: number;
  num_calls_talked: number;
  num_chat: number;
  num_liked: number;
  num_likes_user: number;
  num_post: number;
  num_talked_and_blocked: number;
  num_users_talked: number;
  place_born: string;
  place_live: string;
  recent_access: boolean
  total_talktime: number;
}

export interface Profile extends ProfileCommon {
  iconSrc: string;
  numFollow: number;
  numFollower: number;
  numPost: number;
  numChat: number;

  imageOrder: number[] | number;
}

export interface RoomCommon {
  id: string;
  name: string;
  category: number;
  status: number;
  type: number;
}

export interface Room extends RoomCommon {
  accessControl: AccessControlType;
  creatorName: string;
  creatorEid: string;
  numChats: number;
  numContents: number;
  numLiked: number;
  numParticipants: number;
  numTotalLiked: number;
  roomIconType: 'chat' | 'image';
  roomImageOrder: number[];
  roomImageIcon: string;
  totalTimeSpent: number;
  isDisplay: boolean;
  createdAt: string;
  updatedAt: string;
}

export const RoomIconType = {
  CHAT: "chat",
  IMAGE: "image",
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type RoomIconType = typeof RoomIconType[keyof typeof RoomIconType];

export const AccessControlType = {
  PUBLISH: "publish",
  LIMITED: "limited"
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccessControlType = typeof AccessControlType[keyof typeof AccessControlType];

export interface ApiResponseRoom extends RoomCommon {
  access_control: number;
  creator_name: string;
  creator_eid: string;
  num_chats: number;
  num_contents: number;
  num_liked: number;
  num_participants: number;
  num_total_liked: number;
  room_image_order: string;
  total_time_spent: number;
  created_at: string;
  updated_at: string;
}

export interface TopicCommon {
  id: string;
  name: string;
  category: number;
}

export interface Topic extends TopicCommon {
  numUsers: number;
  orderId: number;
  picId: number;
}

export interface ApiResponseTopic extends TopicCommon {
  num_users: number;
  order_id: number;
  pic_id: number;
}