import React, { FC } from 'react';
import { Row, Col, Avatar, Card } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';

import { Room, RoomIconType, AccessControlType } from '../../../models/user';
import RoomIconChatSVG from '../../../assets/images/room_icon_chat.svg';
import RoomIconImageSVG from '../../../assets/images/room_icon_image.svg';

export type Props = {
  rooms: Room[];
};

const MyRooms: FC<Props> = ({ rooms }) => (
  <div className="my-rooms">
    <h2 className="title">マイルーム</h2>
    <Row>
      {rooms && rooms.map(room => (
        <Col
          xs={24} sm={24} md={12} lg={12} xl={12}
          key={room.id}
        >
          <Card
            bordered
            className="room-card"
          >
            <Row
              className="row-main"
            >
              <Col
                className="col1"
              >
                <Avatar
                  className="room-icon"
                  shape="square"
                  icon={<FileImageOutlined />}
                  src={room.roomImageIcon}
                  alt={room.name}
                  size={70}
                />
              </Col>
              <Col
                className="col2"
              >
                <img
                  className="room-icon-type"
                  src={room.roomIconType === RoomIconType.CHAT ? RoomIconChatSVG : RoomIconImageSVG}
                  alt={room.roomIconType}
                  width={40}
                  height={40}
                />
              </Col>
              <Col
                className="col3"
              >
                <div className="caption">
                  {room.accessControl === AccessControlType.LIMITED ? `${room.name}の両思い限定ルーム` : `${room.name}の公開ルーム`}
                </div>
                <div className="room-name">{room.name}</div>
              </Col>
            </Row>
            <div className="caption">
              <div className="number">入室者: {room.numParticipants}</div>
              <div className="number">チャット: {room.numChats}</div>
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  </div>
);

export default MyRooms;
