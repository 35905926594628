import { FC } from 'react'
import "../style.scss";
import moment from 'moment';
import { Avatar, Col, Row } from 'antd';
import { FileImageOutlined, HeartOutlined } from '@ant-design/icons';

import { Reply } from '../../../models/post'
import { getIcon } from '../../../features/posts';

export type Props = {
  reply: Reply | undefined
  isMobile: boolean
};

const PostReply: FC<Props> = ({
  reply,
  isMobile
}) => {
  return (
      <div className="reply" key={reply?.name}>
        {isMobile ? (
          <>
            <div className="post-row1">
              <div className="post-left-wrapper">
                <div className="tree"></div>
                <Avatar
                  className="user-avatar"
                  icon={<FileImageOutlined />}
                  src={getIcon(reply!.eid, reply!.image_order)}
                  alt={reply?.name}
                  size={isMobile ? 50 : 141} />
              </div>
              <div className="post-right-wrapper">
                <div className="post-top">
                  <div className="post-name">
                    {reply?.name}
                  </div>
                  <div className="post-time">
                    {moment(reply?.created_at, 'YYYY/MM/DD HH:mm:S').fromNow()}
                  </div>
                </div>
                <div className="post-row3">
                  {reply?.text}
                </div>
              </div>
            </div>
          </>
        ) : (
          <Row className="wrapper-row">
            <Col
              xs={8} sm={5} md={5} lg={4} xl={3}
              className="gutter-row"
            >
              <div className="tree"></div>
              <Avatar
                className="user-avatar"
                icon={<FileImageOutlined />}
                src={getIcon(reply!.eid, reply!.image_order)}
                alt={reply?.name}
                size={100} />
            </Col>
            <Col
              xs={16} sm={16} md={16} lg={16} xl={19}
              className="gutter-row"
            >
              <Row className="reply-row1" gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
                <Col className="gutter-row">
                  <div className="post-name">
                    {reply?.name}
                  </div>
                </Col>
                <Col className="gutter-row">
                  <div className="post-time">
                    {moment(reply?.created_at, 'YYYY/MM/DD HH:mm:S').fromNow()}
                  </div>
                </Col>
              </Row>
              <Row className="reply-row2" gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
                <Col className="gutter-row">
                  {reply?.text}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Col className="gutter-row" span={10} offset={isMobile ? 11: 15}>
          <Row className="reply-row3">
            <HeartOutlined className="heart" />
            <div className="lile-number">{reply?.num_liked}</div>
            <Avatar.Group>
              {(reply?.mini !== null) && reply?.mini.map((v, index) => (
                (index >= 0 && index <= 4) &&
                <>
                  <Avatar
                    key={v.eid}
                    icon={<FileImageOutlined />}
                    src={getIcon(v.eid, v.image_order)}
                    alt="ユーザーアイコン"
                    size={isMobile ? 25 : 50}
                  />
                </>
              ))}
            </Avatar.Group>
          </Row>
        </Col>
      </div>);
}

export default PostReply;
