import React, { useEffect, useState } from 'react'
import "./style.scss";
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Layout } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import { HomeOutlined } from '@ant-design/icons';

import HeaderImage from '../../assets/images/faq_header_logo.png';
import { Faq } from '../../models/faq';
import RelatedFaq from './component/RelatedFaq';
import { fetchFaq } from '../../api/faq';

type ContainerProps = {}

type Props = {
  value: string | undefined
  setValue: (v: string) => void
  initialLists: Faq[] | undefined
  goToPage: (id: number) => void
  searchFaqLists: Faq[]
  goToTop: () => void
  categories: string[]
  goToCategoryPage: (category: string) => void
}

export const FaqPage: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const [faqLists, setFaqLists] = useState<Faq[]>([])
  const [searchFaqLists, setSearchFaqLists] = useState<Faq[]>([])
  const [initialLists, setInitialLists] = useState<Faq[]>()
  const [value, setValue] = useState<string | undefined>('')
  const [categories, setCategories] = useState<string[]>([])

  useEffect(() => {
    fetchFaq().then(res => {
      setFaqLists(res.FAQ)
    }).catch(err => {
      console.log(err, '通信に失敗しました。')
    })
  }, [])

  useEffect(() => {
    const array: Faq[] = [];
    faqLists.forEach(v => {
      if (v.Flag) {
        array.push(v)
      }
    })
    setInitialLists(array)

    // カテゴリ一覧
    let categoryArray: string[] = []
    faqLists.forEach(v => {
      categoryArray.push(v.Category)
    })
    const filteredArray = categoryArray.filter(function (ele, pos) {
      return categoryArray.indexOf(ele) === pos;
    })
    setCategories(filteredArray)
  }, [faqLists])

  useEffect(() => {
    const matched = faqLists.filter((faqList) => faqList.Question.match(`${value}`))
    setSearchFaqLists(matched)
  }, [faqLists, value])

  const goToPage = (id: number) => {
    history.push(`/faq/${id}`)
  }

  const goToCategoryPage = (category: string) => {
    history.push(`/faq/category/${category}`)
  }

  const goToTop = () => {
    history.push("/faq")
  }

  return (
    <UI
      value={value}
      setValue={setValue}
      initialLists={initialLists}
      goToPage={goToPage}
      searchFaqLists={searchFaqLists}
      goToTop={goToTop}
      categories={categories}
      goToCategoryPage={goToCategoryPage}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  value,
  setValue,
  initialLists,
  goToPage,
  searchFaqLists,
  goToTop,
  categories,
  goToCategoryPage
}) => {
  return (
    <>
      <div className="faq-main-container">
        <div className="header-wrapper" onClick={goToTop}>
          <img alt="logo" src={HeaderImage} />
        </div>
        <Layout>
          <Content>
            <div className="faq-container">
              <div>
                {value &&
                  <Breadcrumb separator="▶︎" className="breadcrumb">
                    <Breadcrumb.Item href="/faq" >
                      <HomeOutlined />
                      <span>FAQホーム</span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{value}の検索結果</Breadcrumb.Item>
                  </Breadcrumb>
                }
              </div>
              <div className="input-wrapper">
                {!value && <div className="label">検索したい内容を入力して下さい</div>}
                <input type="text" placeholder="例：ポスト" value={value} onChange={e => setValue(e.target.value)} />
              </div>
              <div className="result">{!value ? `よくある質問` : `検索結果`}</div>
              <RelatedFaq
                value={value}
                initialLists={initialLists}
                goToDetail={goToPage}
                searchFaqLists={searchFaqLists}
              />
              {searchFaqLists.length === 0 &&
                <div className="result-answer">
                  {value}に一致する質問はありません。
                  <br />
                  別のワードを入力してください。
                </div>
              }
            </div>
            <div className="category-container">
              <div className="title">質問カテゴリ</div>
              <div className="category-items">
                {categories.map(category => (
                  <div
                    id={category}
                    className="category"
                    onClick={() => goToCategoryPage(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  )
}