import React, { FC } from 'react';

import AppStoreSVG from '../../../assets/images/app_store.svg';
import GooglePlaySVG from '../../../assets/images/google_play.png';

export type Props = {
};

const AppDownload: FC<Props> = () => (
  <div className="app-download">
    <div className="message">
      「君にこの花」アプリをダウンロードすると<br />ポストが見れるよ！
    </div>
    <img
      src={AppStoreSVG}
      alt="App Store"
      className="app-store"
    />
    <img
      src={GooglePlaySVG}
      alt="Google Play"
      className="google-play"
    />
  </div>
);

export default AppDownload;
