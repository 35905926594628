import React, { FC } from 'react';

import { Profile } from '../../../models/user';

export type Props = {
  profile: Profile;
};

const DetailedProfile: FC<Props> = ({ profile }) => (
  <div className="detailed-profile">
    {[
      {
        category: "最推し",
        text: profile.bestlove
      },
      {
        category: "初恋推し",
        text: profile.firstlove
      },
      {
        category: "傾向",
        text: profile.tendency
      },
      {
        category: "地雷",
        text: profile.jirai
      },
      {
        category: "好きな食べ物",
        text: profile.food
      },
      {
        category: "好きな飲み物",
        text: profile.drink
      },
      {
        category: "職業",
        text: profile.job
      },
      {
        category: "ざっくり年齢幅",
        text: profile.agerange
      },
      {
        category: "居住地",
        text: profile.placeLive
      },
    ].map(({ category, text }) => (
      <>
        {text && (
          <div
            key={category}
            className="profile-row"
          >
            <div>{category}：</div>
            <div className="content">{text}</div>
          </div>
        )}
      </>
    ))}
  </div>
);

export default DetailedProfile;
