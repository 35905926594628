import { FC } from 'react'
import "../style.scss";
import moment from 'moment';
import 'moment/locale/ja'
import { Avatar, Col, Divider, Row } from 'antd';
import { FileImageOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';

import { getIcon, getPostImages } from '../../../features/posts';
import { SinglePost } from '../../../models/post';
import { Profile } from '../../../models/user';

export type Props = {
  singlepost: SinglePost
  profile: Profile
  isMobile: boolean
};

const Post: FC<Props> = ({
  singlepost,
  profile,
  isMobile
}) => {
  const { name, text, eid, image_order, created_at, num_liked, likesTimeLine, num_images } = singlepost
  const { numFollow, numFollower, numPost, numChat } = profile

  return (
    <Row className="wrapper-row">
      {isMobile ? (
        <>
          <div className="name">
            {name}
          </div>
          <Divider className="line" />
          <div className="post-row1">
            <div className="post-left-wrapper">
              <Avatar
                className="user-avatar"
                icon={<UserOutlined />}
                src={getIcon(eid, image_order)}
                alt={name}
                size={isMobile ? 50 : 141}
              />
            </div>
            <div className="post-right-wrapper">
              <div className="post-singlepost">
                <div className="post-name">
                  {name}
                </div>
                <div className="post-time">
                  {moment(created_at, 'YYYY/MM/DD HH:mm:S').fromNow()}
                </div>
              </div>
              <div className="text">{text}</div>
            </div>
          </div>
          <div className="post-row3">
            <div className="image-wrapper">
              {num_images > 1 && <div className="num-image">1/{num_images}</div>}
              {num_images !== undefined && getPostImages(eid, image_order, created_at, num_images)?.map((postImage, index) => (
                index === 0 && <img key={index} alt="post" src={postImage} />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <Col
            xs={8} sm={5} md={7} lg={5} xl={5}
            className="gutter-row"
          >
            <Avatar
              className="user-avatar"
              icon={<FileImageOutlined />}
              src={getIcon(eid, image_order)}
              alt={name}
              size={isMobile ? 50 : 141}
            />
          </Col>
          <Col
            xs={16} sm={16} md={16} lg={19} xl={19}
            className="gutter-row"
          >
            <Row className="post-row1" gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
              <Col className="gutter-row" >
                <div className="post-name">
                  {name}
                </div>
              </Col>
              <Col className="gutter-row" >
                <div className="post-time">
                  {moment(created_at, 'YYYY/MM/DD HH:mm:S').fromNow()}
                </div>
              </Col>
            </Row>
            <Row className="post-row2" gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
              <Col className="gutter-row" >
                フォロー: <div className="number">{numFollow}</div>
              </Col>
              <Col className="gutter-row" >
                フォロワー: <div className="number">{numFollower}</div>
              </Col>
              <Col className="gutter-row" >
                ポスト: <div className="number">{numPost}</div>
              </Col>
              <Col className="gutter-row" >
                チャット: <div className="number">{numChat}</div>
              </Col>
            </Row>
          </Col>
          <Row className="post-row3">
            <Col className="gutter-row">
              {text}
            </Col>
            <Col className="image-wrapper">
              {num_images > 1 && <div className="num-image">1/{num_images}</div>}
              {num_images !== undefined && getPostImages(eid, image_order, created_at, num_images)?.map((postImage, index) => (
                index === 0 && <img key={index} alt="post" src={postImage} />
              ))}
            </Col>
          </Row>
        </>
      )}
      <Col className="gutter-row" span={13} offset={isMobile ? 11: 15}>
        <Row className="post-row4">
          <HeartOutlined className="heart" />
          <div className="lile-number">{num_liked}</div>
          <Avatar.Group>
            {likesTimeLine !== null && likesTimeLine?.map((v, index) => (
              (index >= 0 && index <= 4) &&
              <>
                <Avatar
                  key={index}
                  icon={<FileImageOutlined />}
                  src={getIcon(v.eid, v.image_order)}
                  alt="ユーザーアイコン"
                  size={isMobile ? 25 : 50}
                />
              </>
            ))}
          </Avatar.Group>
        </Row>
      </Col>
    </Row>
  )
};

export default Post;
