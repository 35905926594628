import React, { FC } from 'react';
import { Row, Col, Avatar, Button } from 'antd';
import { UserOutlined } from "@ant-design/icons";

import { Profile } from '../../../models/user';

export type Props = {
  profile: Profile;
  isMobile: boolean;
};

const BasicProfile: FC<Props> = ({ profile, isMobile }) => (
  <div className="basic-profile">
    {isMobile && (
      <div className="sp-profile-name">
        {profile.name}
      </div>
    )}
    <div className="basic-profile-content">
      <Row className="wrapper-row">
        <Col
          xs={8} sm={8} md={5} lg={5} xl={5}
          className="gutter-row" 
        >
          <Avatar
            className="user-avatar"
            icon={<UserOutlined />}
            src={profile.iconSrc}
            alt={profile.name}
            size={isMobile ? 100 : 141}
          />
        </Col>
        <Col 
          xs={16} sm={16} md={19} lg={19} xl={19}
          className="gutter-row" 
        >
          <Row className="user-row1">
            {!isMobile && (
              <div className="profile-name">
                {profile.name}
              </div>
            )}
            <div className="follow-btn">
              <Button danger shape="round" size="middle">
                フォローする
              </Button>
            </div>
          </Row>
          <Row className="user-row2" gutter={{ xs: 8, sm: 16, md: 32, lg: 32 }}>
            <Col className="gutter-row" >
              フォロー: <div className="number">{profile.numFollow}</div>
            </Col>
            <Col className="gutter-row" >
              フォロワー: <div className="number">{profile.numFollower}</div>
            </Col>
            <Col className="gutter-row" >
              ポスト: <div className="number">{profile.numPost}</div>
            </Col>
            <Col className="gutter-row" >
              チャット: <div className="number">{profile.numChat}</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <p className="introduction">
        {profile.intro}
      </p>
    </div>
  </div>
);

export default BasicProfile;
