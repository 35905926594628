import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from 'antd';

import HeaderImage from '../../assets/images/header_logo.png';
import { BasicProfile, MyRooms, ConversationTopics, DetailedProfile, AppDownload } from './components';
import { userThunks } from '../../features/users';
import { userSelectors } from '../../features/users';
import useMediaQuery from '../../utils/useMediaQuery';

import "./style.scss";

interface ParamTypes {
  id: string;
}

const UserPage: React.FC = () => {
  const params = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUser);
  const mediaQuery = useMediaQuery();
  const isMobile = mediaQuery.isS.matches
  const { Header, Content } = Layout;

  const findEId = (num: string) => {
    if (num === "1") {
      return "70066021580416a5636ac5c0cbb369a959fb1999c14ed8f80e1fba6a5864d5e0";
    } else if (num === "2") {
      return "aff4b831be8ca7099da421ef20e43f11f9569ed6e077358a03c49e4c93e98ad2";
    }
    return "";
  }

  useEffect(() => {
    dispatch(userThunks.fetchProfile({ eid: findEId(params.id) }));
  }, [params.id, dispatch]);

  return (
    <div className="user-page">
      <Layout>
        <Header>
          <div className="header-wrapper">
            {!isMobile && <>
              <div className="logo" />
              <div>
                <img alt="君にこの花" src={HeaderImage} />
              </div>
            </>}
          </div>
        </Header>
        <Content>
          {user.profile && (
            <BasicProfile
              profile={user.profile}
              isMobile={isMobile}
            />
          )}
          {user.rooms && (
            <MyRooms
              rooms={user.rooms}
            />
          )}
          {user.topics && (
            <ConversationTopics
              topics={user.topics}
            />
          )}
          {user.profile && (
            <DetailedProfile
              profile={user.profile}
            />
          )}
          {!isMobile && <AppDownload />}
        </Content>
      </Layout>
    </div>
  )
}

export default UserPage;