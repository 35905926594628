import React, { FC } from 'react';
import { Row, Col, Card } from 'antd';

import { Topic } from '../../../models/user';

export type Props = {
  topics: Topic[];
};

const ConversationTopics: FC<Props> = ({ topics }) => (
  <div className="conversation-topics">
    <h2 className="title">会話トピック</h2>
    <Row gutter={[
      {xs: 8, sm: 8, md: 16, lg: 16},
      {xs: 8, sm: 8, md: 16, lg: 16}
    ]}>
      {topics.map((topic: Topic) => (
        <Col
          key={topic.id}
          span={6}
        >
          <Card className="topic-card">
            {topic.name}
          </Card>
        </Col>
      ))}
    </Row>
  </div>
);

export default ConversationTopics;
