import qs from 'qs';

import { ApiResponseUser } from '../models/user';
import instance from './instance';
import routes from './constants';

export const fetchProfileAPI = async (
  eid: string,
): Promise<ApiResponseUser> => {
  const response = await instance.post<ApiResponseUser>(routes.API_USER_PROFILE, 
    qs.stringify({ userEID: eid })
  );
  return response.data;
};