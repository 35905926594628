import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Layout from 'antd/lib/layout';
import Content from 'antd/lib/layout';
import { HomeOutlined } from '@ant-design/icons';

import "./style.scss";

import HeaderImage from '../../assets/images/faq_header_logo.png';
import { Faq } from '../../models/faq';
import { fetchFaq } from '../../api/faq';
import { Breadcrumb } from 'antd';

type ContainerProps = {}

type Props = {
  initialLists: Faq[] | undefined
  goToDetail: (id: number) => void
  goToTop: () => void
  goToPage: (id: string) => void
  params: { category: string }
  categories: string[]
}

export const FaqCategoryPage: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const params = useParams<{ category: string }>()
  const [initialLists, setInitialLists] = useState<Faq[]>()
  const [faqLists, setFaqLists] = useState<Faq[]>([])
  const [categories, setCategories] = useState<string[]>([])

  const goToTop = () => {
    history.push("/faq")
  }

  const goToDetail = (id: number) => {
    history.push(`/faq/${id}`)
  }

  const goToPage = (category: string) => {
    history.push(`/faq/category/${category}`)
  }

  useEffect(() => {
    // 質問一覧
    fetchFaq().then(res => {
      setFaqLists(res.FAQ)
    }).catch(err => {
      console.log(err, '通信に失敗しました。')
    })
  }, [])

  useEffect(() => {
    // カテゴリ一覧
    let categoryArray: string[] = []
    faqLists.forEach(v => {
      categoryArray.push(v.Category)
    })
    const filteredArray = categoryArray.filter(function (ele, pos) {
      return categoryArray.indexOf(ele) === pos;
    })
    setCategories(filteredArray)
  }, [faqLists])

  useEffect(() => {
    const array: Faq[] = [];
    faqLists.forEach(v => {
      if (v.Category === params.category) {
        array.push(v)
      }
    })
    setInitialLists(array)
  }, [faqLists, params.category])

  return <UI
    initialLists={initialLists}
    goToDetail={goToDetail}
    goToTop={goToTop}
    params={params}
    categories={categories}
    goToPage={goToPage}
  />
}

export const UI: React.FC<ContainerProps & Props> = ({
  initialLists,
  goToDetail,
  goToTop,
  params,
  categories,
  goToPage
}) => {
  return (
    <>
      <div className="faq-main-container">
        <div className="header-wrapper" onClick={goToTop}>
          <img alt="logo" src={HeaderImage} />
        </div>
        <Layout>
          <Content>
            <div className="faq-container">
              <Breadcrumb separator="▶︎" className="breadcrumb">
                <Breadcrumb.Item href="/faq" >
                  <HomeOutlined />
                  <span>FAQホーム</span>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{`${params?.category}の質問一覧`}</Breadcrumb.Item>
              </Breadcrumb>
              <div className="result">{`${params?.category}の質問一覧`}</div>
              <div className="faq-bottom">
                {initialLists && initialLists?.map(initialList =>
                  <div key={initialList.Id} className="faq" onClick={() => goToDetail(initialList.Id)}>{initialList.Question}</div>
                )}
              </div>
            </div>
            <div className="category-container">
              <div className="title">質問カテゴリ</div>
              <div className="category-items">
                {categories.map(category => (
                  <div className="category" id={category} onClick={() => goToPage(category)}>{category}</div>
                ))}
              </div>
            </div>
          </Content>
        </Layout>
      </div>
    </>
  )
}
