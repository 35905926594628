import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserState } from "./slice";

export function getUserState(state: RootState): UserState {
  return state.user;
}

export const getUser = createSelector(
  getUserState,
  (user: UserState) => user.data
);

export const getUserProfile = createSelector(
  getUserState,
  (user: UserState) => user.data.profile
);