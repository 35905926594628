import { configureStore } from '@reduxjs/toolkit';

import { userReducers } from './users';

const store = configureStore({
  reducer: {
    user: userReducers
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
