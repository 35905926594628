// ユーザーアイコン取得
export const getIcon = (eid: string, image_order: string) => {
  if (image_order !== '') {
    const splitCommaStringIntoArray = (s: string) => s?.split(",").map((s: string) => parseInt(s));
    const imageOrder = splitCommaStringIntoArray(image_order);
    const iconSrcIdx = imageOrder[0];
    return `https://images.yourblossoms.com/faceimages/users/${eid}/${iconSrcIdx}/middle.jpg`
  }
}

export const getPostImages = (eid: string, image_order: string, created_at: string, num_images: number) => {
  if (image_order !== '') {
    const numImages = [...Array(num_images)].map((_, i) => i + 1)
    const postImages: string[] = []
    numImages.map(numImage => postImages.push(`https://images.yourblossoms.com/timelineimages/users/${eid}/${created_at}/image0${numImage}.jpg`))
    return postImages
  }
}