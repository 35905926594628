import React, { useState, useEffect } from 'react'
import "./style.scss";
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Col, Divider, Layout, Row } from 'antd';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { FileImageOutlined } from '@ant-design/icons';

import { fetchPost } from '../../api/posts';
import HeaderImage from '../../assets/images/header_logo.png';
import AppStoreSVG from '../../assets/images/app_store.svg';
import GooglePlaySVG from '../../assets/images/google_play.png';
import Post from './components/Post';
import PostReply from './components/PostReply';
import { getIcon } from '../../features/posts';
import { userSelectors, userThunks } from '../../features/users';
import { User } from '../../models/user';
import { PostResponse, Reply, SinglePost } from '../../models/post'
import useMediaQuery from '../../utils/useMediaQuery';

interface ParamTypes {
  id: string;
}

type ContainerProps = {}

type Props = {
  singlepost: SinglePost | undefined
  replies: Reply[] | undefined
  user: User
  isMobile: boolean
  showAppBanner: boolean
  setShowAppBanner: (v: boolean) => void
}

export const PostPage: React.FC<ContainerProps> = () => {
  const params = useParams<ParamTypes>();
  const mediaQuery = useMediaQuery();
  const isMobile = mediaQuery.isS.matches

  const dispatch = useDispatch();
  const user = useSelector(userSelectors.getUser);

  const [singlepost, setSinglepost] = useState<SinglePost | undefined>()
  const [replies, setReplies] = useState<Reply[] | undefined>()

  const [showAppBanner, setShowAppBanner] = useState<boolean>(true)

  const _fetchPost = async () => {
    await fetchPost({ timelineid: params.id })
      .then((res: PostResponse) => {
        setSinglepost(res.singlepost)
        setReplies(res?.replies)
      }).catch(() => {
        console.log('通信に失敗しました')
      })
  };

  useEffect(() => {
    if (singlepost) {
      dispatch(userThunks.fetchProfile({ eid: singlepost.eid }));
    }
  }, [singlepost, dispatch])

  useEffect(() => {
    _fetchPost()
  }, [])

  return (
    <UI
      replies={replies}
      singlepost={singlepost}
      user={user}
      isMobile={isMobile}
      showAppBanner={showAppBanner}
      setShowAppBanner={setShowAppBanner}
    />
  )
}

export const UI: React.FC<ContainerProps & Props> = ({
  singlepost,
  replies,
  user,
  isMobile,
  showAppBanner,
  setShowAppBanner
}) => {
  return (
    <>
      {singlepost &&
        <div className="post-page">
          <Layout>
            <Header>
              <div className="header-wrapper">
                {!isMobile && (
                  <>
                    <div className="logo" />
                    <div>
                      <img alt="君にこの花" src={HeaderImage} />
                    </div>
                  </>
                )}
              </div>
            </Header>

            <Layout>
              <Content>
                {user.profile && <Post isMobile={isMobile} singlepost={singlepost} profile={user.profile} />}
                {(!isMobile || (replies && replies.length !== 0)) && <Divider className="line" />}
                {replies ?
                  replies.map(reply =>
                    <PostReply key={reply.id} reply={reply} isMobile={isMobile} />
                  ) : !isMobile && <div className="bottom" />
                }
              </Content>
            </Layout>

            <Footer>
              {isMobile ? (
                <Row className="app-download">
                  <Avatar size={25} alt="ユーザーアイコン" icon={<FileImageOutlined />} src={getIcon(singlepost.eid, singlepost.image_order)} />
                  <div className="gutter-row message">
                    {singlepost.name}さんの他のポストを見る
                  </div>
                  <div className="button" onClick={() => window.location.href = 'https://apps.apple.com/jp/app/%E3%82%AA%E3%82%BF%E3%82%AF%E5%A5%B3%E5%AD%90sns-%E5%90%9B%E3%81%AB%E3%81%93%E3%81%AE%E8%8A%B1/id1486295504'}>アプリを開く</div>
                </Row>
              ) : (
                <Row className="app-download">
                  <Col className="gutter-row" >
                    <Avatar size={50} alt="ユーザーアイコン" icon={<FileImageOutlined />} src={getIcon(singlepost.eid, singlepost.image_order)} />
                  </Col>
                  <Col className="gutter-row message" >
                    {singlepost.name}さんの他のポストを見る
                  </Col>
                  <Col className="gutter-row" onClick={() => window.location.href = 'https://apps.apple.com/jp/app/%E3%82%AA%E3%82%BF%E3%82%AF%E5%A5%B3%E5%AD%90sns-%E5%90%9B%E3%81%AB%E3%81%93%E3%81%AE%E8%8A%B1/id1486295504'} >
                    <img
                      src={AppStoreSVG}
                      alt="App Store"
                      className="app-store"
                    />
                  </Col>
                  <Col className="gutter-row" onClick={() => window.location.href = 'https://androidapp.jp.net/apk/1486295504/%E3%82%AA%E3%82%BF%E3%82%AF%E5%A5%B3%E5%AD%90sns-%E5%90%9B%E3%81%AB%E3%81%93%E3%81%AE%E8%8A%B1'}>
                    <img
                      src={GooglePlaySVG}
                      alt="Google Play"
                      className="google-play"
                    />
                  </Col>
                </Row>
              )}
            </Footer>

          </Layout>
        </div>
      }
    </>
  )
}
