import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import './App.scss';
import UserPage from './pages/UserPage';
import { PostPage } from './pages/PostPage';
import { FaqPage } from './pages/FaqPage';
import { FaqDetailPage } from './pages/FaqPage/detail';
import { FaqCategoryPage } from './pages/FaqPage/category';
import { QuestionPage } from './pages/QuestionPage';

export const App: React.FC = () => {
  return (
    <div className="main">
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={() => {
            window.location.href = 'https://apps.apple.com/jp/app/%E3%82%AA%E3%82%BF%E3%82%AF%E5%A5%B3%E5%AD%90sns-%E5%90%9B%E3%81%AB%E3%81%93%E3%81%AE%E8%8A%B1/id1486295504';
            return null;
          }} />
          <Route exact path="/user/:id" component={UserPage} />
          <Route exact path="/post/:id" component={PostPage} />
          <Route exact path="/faq" component={FaqPage} />
          <Route exact path="/faq/category/:category" component={FaqCategoryPage} />
          <Route exact path="/faq/:id" component={FaqDetailPage} />
          <Route exact path="/question" component={QuestionPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
