import axios from 'axios';
import { API_BASE_URL } from './constants';

const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 1000,
  headers: {
    'webtoken': 'kd6Vni924$$sdiopWe3Nvfs7Al',
    'content-type': 'application/x-www-form-urlencoded',
  }
});

export default instance;