import axios from "axios";
import { ALTERAPI_BASE_URL } from "./constants";

const instanceQuestion = axios.create({
  baseURL: ALTERAPI_BASE_URL,
  headers: {
    'webtoken': 'PL8ebX9faq44EwdannmjDcxi',
    'content-type': 'application/json',
  }
});

export default instanceQuestion;