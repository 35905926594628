import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchProfileAPI } from '../../api/users';
import { ApiResponseUser } from '../../models/user';

type UserParams = {
  eid: string;
};

export const fetchProfile = createAsyncThunk<
  { user: ApiResponseUser },
  UserParams
>(
  'users/fetchUser',
  async ({ eid }, { dispatch }) => {
    try {
      const user = await fetchProfileAPI(eid);
      return { user };
    } catch (error) {
      throw error;
    }
  },
);