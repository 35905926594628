import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchProfile } from './thunks';
import { User, convertFileldsFromApiResponseUser } from '../../models/user';

export interface UserState {
  email: string;
  authenticated: boolean;
  data: User;
  loading: string;
}

const userSlice = createSlice({
  name: 'user',
  initialState: {
    email: '',
    authenticated: false,
    data: {},
    loading: 'idle'
  },
  reducers: {
    login: (state, action: PayloadAction<{ email: string }>) => {
      state.authenticated = true;
      state.email = action.payload.email;
    },
    logout: (state) => {
      state.authenticated = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchProfile.pending, (state, action) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.data = convertFileldsFromApiResponseUser(action.payload.user);
      state.loading = 'fulfilled';
    });
    builder.addCase(fetchProfile.rejected, (state, action) => {
      state.loading = 'rejected';
    });
  }
});

export const userActions = userSlice.actions;
export const userReducers = userSlice.reducer;