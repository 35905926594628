import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import "./style.scss";

import HeaderImage from '../../assets/images/faq_header_logo.png';
import { Faq } from '../../models/faq';
import { fetchFaq } from '../../api/faq';

type ContainerProps = {}

type Props = {
  faq: Faq | undefined
  initialLists: Faq[] | undefined
  goToDetail: (id: number) => void
  goToTop: () => void
  params: { category: string; id: string }
}

export const FaqDetailPage: React.FC<ContainerProps> = () => {
  const history = useHistory()
  const params = useParams<{ category: string; id: string }>()

  const [faq, setFaq] = useState<Faq>()
  const [faqLists, setFaqLists] = useState<Faq[]>([])
  const [initialLists, setInitialLists] = useState<Faq[]>()

  const goToTop = () => {
    history.push("/faq")
  }

  const goToDetail = (id: number) => {
    history.push(`/faq/${id}`)
  }

  useEffect(() => {
    fetchFaq().then(res => {
      setFaqLists(res.FAQ)
    }).catch(err => {
      console.log(err, '通信に失敗しました。')
    })
  }, [])

  useEffect(() => {
    const matchFaq = faqLists.filter(faqList => faqList.Id === Number(params.id))
    setFaq(matchFaq[0])
    window.scrollTo(0, 0)
  }, [faqLists, params.id])

  useEffect(() => {
    const array: Faq[] = [];
    const fetchAnswers = () => {
      faqLists.forEach(faqList => {
        // 関連ページは同じカテゴリーで見ている質問以外を表示
        if (faqList.Category === faq?.Category && faqList.Id !== faq?.Id) {
          array.push(faqList)
        }
      })
      setInitialLists(array)
    }
    fetchAnswers()
  }, [faq?.Category, faq?.Id, faqLists])

  return <UI
    faq={faq}
    initialLists={initialLists}
    goToDetail={goToDetail}
    goToTop={goToTop}
    params={params}
  />
}

export const UI: React.FC<ContainerProps & Props> = ({
  faq,
  initialLists,
  goToDetail,
  goToTop,
  params
}) => {
  // 改行
  const texts = faq?.Answer.split("\n").map((item, index) => (
    <React.Fragment key={index}>{item}<br /></React.Fragment>
  ))

  return (
    <>
      <div className="detail-main-container">
        <div className="detail-header-wrapper">
          <img alt="logo" src={HeaderImage} onClick={goToTop} />
        </div>
        <div className="detail-container">
          <Breadcrumb separator="▶︎" className="breadcrumb">
            <Breadcrumb.Item href="/faq" >
              <HomeOutlined />
              <span>FAQホーム</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item href={`/faq/category/${faq?.Category}`}>{faq?.Category}</Breadcrumb.Item>
            <Breadcrumb.Item>{faq?.Question}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="faq-top">
            <div className="title">{faq?.Question}に関する回答</div>
            <div className="line"></div>
            <div className="answer">{texts}</div>
            {faq?.URL &&
              <img alt="logo" className="faq-image" src={faq?.URL} onClick={goToTop} />
            }
          </div>
        </div>
        <div className="faq-bottom">
          <div className="title">関連ページ</div>
          {initialLists && initialLists?.map(initialList =>
            <div
              key={initialList.Id}
              className="faq"
              onClick={() => goToDetail(initialList.Id)}>
              {initialList.Question}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
