import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom";
import { fetchQuestion } from "../../api/questions"
import AppStoreSVG from '../../assets/images/app_store.svg';
import twitterQuestion from '../../assets/images/twitter_question.png'
import { QuestionType } from "./model";
import "./style.scss";

type QuestionPageProps = {}

export const QuestionPage: React.FC<QuestionPageProps> = () => {
  const [question, setQuestion] = useState<QuestionType>()

  function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const _fetchQuestion = async () => {
    await fetchQuestion(query.get("timeline_id")).then(res => {
      setQuestion(res[0])
    })
  }

  useEffect(() => {
    _fetchQuestion()
  }, [])

  return (
    <>
      <div>{question?.question}</div>
      <div>{question?.text}</div>
      <div>
        <img
          src={twitterQuestion}
          alt="question"
          className="question-image"
        />
      </div>
      <div onClick={() => window.location.href = 'https://yourblossoms.page.link/tm8Q'}>
        <img
          src={AppStoreSVG}
          alt="App Store"
          className="app-store"
        />
      </div>
    </>
  )
}