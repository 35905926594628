import { useState, useEffect } from 'react';

const useMediaQuery = (): {
  isS: MediaQueryList,
  isM: MediaQueryList,
  isL: MediaQueryList
} => {
  const [mq, setMq] = useState({
    isS: window.matchMedia("screen and (max-width: 600px)"),
    isM: window.matchMedia("screen and (min-width: 601px) and (max-width: 960px)"),
    isL: window.matchMedia("screen and (min-width: 961px)")
  })

  useEffect(() => {
    const onResize = () => {
      setMq({
        isS: window.matchMedia("screen and (max-width: 600px)"),
        isM: window.matchMedia("screen and (min-width: 601px) and (max-width: 960px)"),
        isL: window.matchMedia("screen and (min-width: 961px)")
      })
    }

    window.addEventListener('resize', onResize);
    window.addEventListener('load', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('load', onResize);
    }
  })

  return mq;
}

export default useMediaQuery;